<template>
    <div style="height:100%">
        <transition-group name="fade" mode="out-in">
            <!-- <weacg-loading :key="0"></weacg-loading> -->
            <div :key="1">
                <div class="birthday">
                    <div class="imgLoader"></div>

                    <div class="container">
                        <h1 class="title">{{ isCustom ? 'Welcome' : baseData.isToday ? 'Happy BirthDay' : 'Happy Everyday'
                        }}</h1>
                        <div class="credit">
                            * May all the beauty be blessed
                        </div>

                        <div class="book">
                            <div class="gap"></div>
                            <div class="pages">
                                <div class="page"></div>
                                <div class="page"></div>
                                <div class="page"></div>
                                <div class="page"></div>
                                <div class="page"></div>
                                <div class="page"></div>
                            </div>
                            <div class="flips">
                                <div class="flip flip1">
                                    <div class="flip flip2">
                                        <div class="flip flip3">
                                            <div class="flip flip4">
                                                <div class="flip flip5">
                                                    <div class="flip flip6">
                                                        <div class="flip flip7"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="!isCustom">
                        <weacg-text-btn v-if="baseData.isToday" title="去许愿" @openDialog="openDialog" type="wish">
                        </weacg-text-btn>
                        <!-- <weacg-text-btn class="main-wish-btn" v-if="true" title="去许愿" @openDialog="openDialog" type="wish">
                        </weacg-text-btn> -->
                        <div v-else class="not_current_day_text">
                            <p>
                                距离你的下一次生日还有{{ baseData.distance }}天哦
                            </p>
                        </div>
                    </div>
                </div>
                <div class="time">
                    <div class="countUp">
                        <weacg-countUp :isTime="true" :title="countTitle"
                            :currentYearDiaryCount="baseData.currentYearDiaryCount"
                            :currentYearImgCount="baseData.currentYearImgCount"
                            :currentYearTimeLineCount="baseData.currentYearTimeLineCount"
                            :currentYearWishCount="baseData.currentYearWishCount"></weacg-countUp>

                        <weacg-time-line2 ref="timeline" :totalCount="baseData.timeCount" :timeData="baseData.timeLineList"
                            @timePageChange="getBaseData('time')"></weacg-time-line2>
                        <!-- <weacg-text-btn v-if="!isCustom && getUnlockMod(0)" title="去记录" type="timeline"
                            @openDialog="openDialog">
                        </weacg-text-btn> -->
                    </div>
                </div>
                <div v-if="!isCustom" id="action_btn" class="action_btn">
                    <weacg-anim-button ref="diary" @unlock="unlock" @read="read" @read-no-secret="readNoSecret"
                        :diaryData="baseData.diaryList" :totalCount="baseData.diaryCount"
                        @diaryPageChange="getBaseData('diary')">
                    </weacg-anim-button>
                    <!-- <weacg-text-btn class="main-diary-btn" title="写日记" type="diary" @openDialog="openDialog">
                    </weacg-text-btn> -->
                </div>
                <div class="audio" v-if="!isCustom">
                    <weacg-audio-player :musicData="currenMusicData"></weacg-audio-player>
                    <!-- <a href="https://gitee.com/tg1603749510" class="twitterLink" target="_top">Powered By A2B9S -->
                    <!-- </a> -->
                </div>
                <div v-if="!isCustom" class="genPwd">
                    <div class="pwd-btn" v-loading="loadingPwd" @click="CreateCustomPwd">
                        <transition-group name="fade">
                            <p class="pwd-span" :key="1" v-show="!showPwdbtnAnim">{{ pwdbtn }}</p>
                            <p class="pwd-span" :key="2" v-show="showPwdbtnAnim">{{ pwdbtnAnim }}</p>
                        </transition-group>
                    </div>

                </div>
                <weacg-message v-if="isCustom"></weacg-message>
                <div class="power_info">
                    <div class="loginout" @click="loginout">退出登录</div>
                    <div class="time_out">
                        <weacg-float-menu v-if="!isCustom" :menuList="menuList"></weacg-float-menu>
                    </div>
                    <a href="https://gitee.com/tg1603749510" class="twitterLink" target="_top">All By A2B9S
                    </a>
                </div>

                <weacg-letter-dialog @load-data="refreahData" ref="letter"></weacg-letter-dialog>
                <weacg-wish-Dialog ref="inputDialog" :type="btnType" :diaryId="diarytId" @open-letter="openLetter"
                    @load-data="refreahData"></weacg-wish-Dialog>
                <weacg-loading v-if="showLoading"></weacg-loading>
            </div>
        </transition-group>
    </div>
</template>

<script>
import {
    CreateCustomPwd,
    GetAllBaseData,
    GetUserType
} from '@/api/api'
import { Alert } from 'element-ui'
import { isNotEmptyStr } from '../../utils/index'

export default {
    data() {
        return {
            isLoading: false,
            pwdbtn: '生成访客密码',
            pwdbtnAnim: '重新生成访客密码',
            showPwdbtnAnim: false,
            timer: null,
            baseData: {
                currentYearDiaryCount: 0,
                currentYearImgCount: 0,
                currentYearTimeLineCount: 0,
                currentYearWishCount: 0,
                diaryList: [],
                wishList: [],
                timeLineList: [],
                isToday: false,
                distance: '--'
            },
            menuList: [
                { name: '记录时光', callback: () => { this.openDialog('timeline') } },
                { name: '写日记', callback: () => { this.openDialog('diary') } }],
            baseUrl: this.$weacg.BaseUrl,
            btnType: 'wish',
            diarytId: '',
            showLoading: false,
            loadingPwd: false,
            nextTime: '2022/06/29',
            planTime: ['2022-06-29 09:00:00', '2022-06-29 12:00:00', '2022-06-29 18:00:00', '2022-06-29 21:00:00'],
            musicData: {
                '2022': {
                    data: [
                        {
                            title: '生日快乐',
                            artist: '石头',
                            src: `${this.$weacg.BaseUrl}/music/2022/1.mp3`,
                            pic: `${this.$weacg.BaseUrl}/music/2022/pic/1.jpg`,
                            lrc: `${this.$weacg.BaseUrl}/api/Birthday/GetLrc?id=1`
                        },
                        {
                            title: '一杯月',
                            artist: '陈致逸 / 银临',
                            src: `${this.$weacg.BaseUrl}/music/2022/3.mp3`,
                            pic: `${this.$weacg.BaseUrl}/music/2022/pic/3.jpg`,
                            lrc: `${this.$weacg.BaseUrl}/api/Birthday/GetLrc?id=3`
                        },
                        {
                            title: '心跳',
                            artist: '徐梦圆',
                            src: `${this.$weacg.BaseUrl}/music/2022/4.mp3`,
                            pic: `${this.$weacg.BaseUrl}/music/2022/pic/4.jpg`,
                            lrc: `${this.$weacg.BaseUrl}/api/Birthday/GetLrc?id=4`
                        },
                        {
                            title: '一介书生',
                            artist: '江潮',
                            src: `${this.$weacg.BaseUrl}/music/2022/6.mp3`,
                            pic: `${this.$weacg.BaseUrl}/music/2022/pic/6.jpg`,
                            lrc: `${this.$weacg.BaseUrl}/api/Birthday/GetLrc?id=6`
                        },
                        {
                            title: '心事婉约',
                            artist: '苏星婕',
                            src: `${this.$weacg.BaseUrl}/music/2022/9.mp3`,
                            pic: `${this.$weacg.BaseUrl}/music/2022/pic/9.jpg`,
                            lrc: `${this.$weacg.BaseUrl}/api/Birthday/GetLrc?id=9`
                        },
                        {
                            title: '我乐意',
                            artist: '许嵩',
                            src: `${this.$weacg.BaseUrl}/music/2022/10.mp3`,
                            pic: `${this.$weacg.BaseUrl}/music/2022/pic/10.jpg`,
                            lrc: `${this.$weacg.BaseUrl}/api/Birthday/GetLrc?id=10`
                        }
                    ],
                    default: {
                        title: '生日快乐',
                        artist: '石头',
                        src: `${this.$weacg.BaseUrl}/music/2022/1.mp3`,
                        pic: `${this.$weacg.BaseUrl}/music/2022/pic/1.jpg`,
                        lrc: `${this.$weacg.BaseUrl}/api/Birthday/GetLrc?id=1`
                    },
                    link: 'https://music.163.com/#/playlist?id=7477150333',
                    title: 'Album For 2022'
                },
                '2023': {
                    data: [
                        {
                            title: 'やわらかな光',
                            artist: 'やまだ豊',
                            src: `${this.$weacg.BaseUrl}/music/2023/1.mp3`,
                            pic: `${this.$weacg.BaseUrl}/music/2023/pic/1.jpg`,
                            lrc: `${this.$weacg.BaseUrl}/api/Birthday/GetLrc?id=11`
                        },
                        {
                            title: 'You Raise Me Up',
                            artist: 'Westlife',
                            src: `${this.$weacg.BaseUrl}/music/2023/2.mp3`,
                            pic: `${this.$weacg.BaseUrl}/music/2023/pic/2.jpg`,
                            lrc: `${this.$weacg.BaseUrl}/api/Birthday/GetLrc?id=12`
                        },
                        {
                            title: '一笑倾城',
                            artist: '汪苏泷',
                            src: `${this.$weacg.BaseUrl}/music/2023/3.mp3`,
                            pic: `${this.$weacg.BaseUrl}/music/2023/pic/3.jpg`,
                            lrc: `${this.$weacg.BaseUrl}/api/Birthday/GetLrc?id=13`
                        },
                        {
                            title: '早晨的第一道光芒与你的微笑',
                            artist: 'VK克',
                            src: `${this.$weacg.BaseUrl}/music/2023/4.mp3`,
                            pic: `${this.$weacg.BaseUrl}/music/2023/pic/4.jpg`,
                            lrc: `${this.$weacg.BaseUrl}/api/Birthday/GetLrc?id=14`
                        },
                        {
                            title: '还是你的笑容最可爱',
                            artist: '音阙诗听/泠鸢yousa/王梓钰',
                            src: `${this.$weacg.BaseUrl}/music/2023/5.mp3`,
                            pic: `${this.$weacg.BaseUrl}/music/2023/pic/5.jpg`,
                            lrc: `${this.$weacg.BaseUrl}/api/Birthday/GetLrc?id=15`
                        },
                        {
                            title: '此曲赠与君',
                            artist: '王梓钰',
                            src: `${this.$weacg.BaseUrl}/music/2023/6.mp3`,
                            pic: `${this.$weacg.BaseUrl}/music/2023/pic/6.jpg`,
                            lrc: `${this.$weacg.BaseUrl}/api/Birthday/GetLrc?id=16`
                        }
                    ],
                    default: {
                        title: '此曲赠与君',
                        artist: '王梓钰',
                        src: `${this.$weacg.BaseUrl}/music/2023/6.mp3`,
                        pic: `${this.$weacg.BaseUrl}/music/2023/pic/6.jpg`,
                        lrc: `${this.$weacg.BaseUrl}/api/Birthday/GetLrc?id=16`
                    },
                    link: 'https://music.163.com/#/playlist?id=8418621485',
                    title: 'Album For 2023'
                }
            }
            // audio: new Audio()
        }
    },
    computed: {
        countTitle() {
            return this.$store.state.isCustom ? '见证我们的时光' : '与你相识的'
        },
        isCustom() {
            return this.$store.state.isCustom
        },
        currenMusicData() {
            let targetDate = new Date('2022/05/19 15:07:00')
            // Get the current date
            var currentDate = new Date()

            // Find the difference between dates
            var diff = (currentDate - targetDate) / 1000
            diff = Math.abs(Math.floor(diff))

            // Check number of days until target
            let day = Math.floor(diff / (24 * 60 * 60))
            let year = day ? parseInt(day / 365) : 0
            let currentMusicIndex = 2022 + year
            return this.musicData[`${currentMusicIndex}`]

        },
    },
    created() {
        // 在页面刷新时将vuex里的信息保存到sessionStorage里
        // beforeunload事件在页面刷新时先触发
        window.addEventListener('pagehide', () => {
            try {
                localStorage.setItem('store', JSON.stringify(this.$store.state))
            } catch (e) {
                console.log(e)
                //无痕模式
                // window.customStore = JSON.stringify(this.$store.state)
            }
        })



        this.$bus.$on('showLoading', () => {
            this.showLoadingToast()
        })
        this.$bus.$on('closeLoading', () => {
            this.hideLoadingToast()
        })
        this.$bus.$on('passget', value => {
            this.PassGet(value.id, value.pass)
        })
    },
    methods: {
        getUnlockMod(type) {
            const time = this.$moment(Date.now(), 'YYYY-MM-DD hh:mm:ss');
            switch (type) {
                case 0: {
                    return this.$moment().diff(this.$moment(this.planTime[1]), 'seconds') > 0;
                }
                case 1: {
                    return this.$moment().diff(this.$moment(this.planTime[2]), 'seconds') > 0;
                }
                case 2: {
                    return this.$moment().diff(this.$moment(this.planTime[3]), 'seconds') > 0;
                }
            }
        },
        CreateCustomPwd() {
            clearInterval(this.timer);
            this.loadingPwd = true
            setTimeout(() => {
                CreateCustomPwd({ token: this.$store.state.token }).then(res => {
                    if (res) {
                        this.pwdbtn = res

                        const clipboardObj = navigator.clipboard;

                        if (clipboardObj && window.isSecureContext) {
                            // navigator clipboard 向剪贴板写文本
                            clipboardObj.writeText(res).then(() => {
                                this.$notify({
                                    title: '复制成功',
                                    message: '快去分享密码吧',
                                    duration: 3000
                                })
                            }, () => {
                                this.$notify.error({
                                    title: '复制失败',
                                    message: '当前浏览器不支持复制操作',
                                    duration: 3000
                                })
                            });
                        } else {
                            this.$notify.error({
                                title: '复制失败',
                                message: '当前浏览器不支持复制操作',
                                duration: 3000
                            })
                        }
                        setTimeout(() => {
                            this.timer = setInterval(() => {
                                if (this.showPwdbtnAnim) {
                                    this.showPwdbtnAnim = false
                                } else {
                                    this.showPwdbtnAnim = true
                                }
                            }, 5000);
                        }, 3000)
                    } else {
                        this.$notify.error({
                            title: '错误',
                            message: '请检查用户身份信息',
                            duration: 3000
                        })
                    }
                })
                    .catch(() => {
                        this.$notify.error({
                            title: '错误',
                            message: '获取失败，请检查网络',
                            duration: 3000
                        })
                    })
                    .finally(() => {
                        this.loadingPwd = false
                    })
            }, 2000)

        },
        PassGet(id, pass) {
            this.$notify({
                title: '解锁成功',
                message: `${pass}是该日记的密码，请收好，手动关闭本弹窗即可打开日记`,
                position: 'top-right',
                duration: 0,
                onClose: () => {
                    this.getLetter(id, pass)
                }
            })
        },
        getLetter(id, sercet) {
            this.axios
                .get(`Diary/GetDd?did=${id}&sercet=${sercet}`)
                .then(res => {
                    if (res.data.success === true) {
                        this.openLetter(res.data.response)
                    } else {
                        this.$notify({
                            title: '消息',
                            message: res.data.msg,
                            position: 'top-right'
                        })
                    }
                })
        },
        getBaseData(type = 'default') {
            this.showLoadingToast()
            if (type === 'diary') {
                this.baseData.diaryList = [];
            }
            if (type === 'time') {
                this.baseData.timeLineList = [];
            }

            this.axios
                .get(`Birthday/GetAllBaseData?timeIndex=${this.$refs.timeline ? this.$refs.timeline.currentPage : 1}&diaryIndex=${this.$refs.diary ? this.$refs.diary.currentPage : 1}`)
                .then(res => {
                    if (res && res.data && res.data.response) {
                        this.baseData = res.data.response
                    }
                    this.hideLoadingToast()
                    this.$nextTick(() => {
                        this.$bus.$emit('load-complete')
                    })
                })
                .catch(() => {
                    this.hideLoadingToast()
                })
        },
        showLoadingToast() {
            if (this.showLoading === false) {
                this.showLoading = true
            }
        },
        hideLoadingToast() {
            if (this.showLoading === true) {
                this.showLoading = false
            }
        },
        refreahData() {
            this.getBaseData()
        },
        changePage() {
            this.isLoading = false
        },
        openDialog(type) {
            this.$refs.inputDialog.type = type
            this.$nextTick(() => {
                this.$refs.inputDialog.visible = true
            })
        },
        openLetter(context) {
            this.$refs.letter.context = context
            this.$nextTick(() => {
                this.$refs.letter.visible = true
            })
        },
        unlock(id) {
            this.$refs.inputDialog.diaryId = id
            this.$refs.inputDialog.type = 'whisper'
            this.$nextTick(() => {
                this.$refs.inputDialog.visible = true
            })
        },
        readNoSecret(id) {
            try {
                this.$bus.$emit('showLoading')
                this.axios
                    .get(
                        `Diary/GetDdNoSecret?did=${id}`
                    )
                    .then(res => {
                        if (res.data.success === true) {
                            this.$bus.$emit('closeLoading')
                            this.openLetter(res.data.response)
                        } else {
                            this.$bus.$emit('closeLoading')
                            this.$notify({
                                title: '消息',
                                message: res.data.msg,
                                position: 'top-right'
                            })
                        }
                    })
            } catch {
                this.$bus.$emit('closeLoading')
            }
        },
        read(id) {
            this.$refs.inputDialog.diaryId = id
            this.$refs.inputDialog.type = 'unlock'
            this.$nextTick(() => {
                this.$refs.inputDialog.visible = true
            })
        },
        loginout() {
            this.$store.commit('saveToken', '')
            this.$store.commit('saveTokenExpire', '')
            this.$store.commit('saveTagsData', '')
            window.localStorage.removeItem("store");
            window.localStorage.removeItem('user')
            window.localStorage.removeItem('NavigationBar')
            window.localStorage.removeItem("Token");
            window.localStorage.removeItem("TokenExpire");
            window.localStorage.removeItem("refreshtime");
            window.localStorage.removeItem("expires_in");
            this.$router.replace({
                name: 'login',
            })
        },
        setNextTime() {
            if (this.$moment().diff(this.$moment(this.planTime[3]), 'seconds') > 0) {
                return
            }
            let next = null;
            if (this.$moment().diff(this.$moment(this.planTime[0]), 'seconds') < 0) {
                next = this.planTime[0]
            } else if (this.$moment().diff(this.$moment(this.planTime[1]), 'seconds') < 0) {
                next = this.planTime[1]
            } else if (this.$moment().diff(this.$moment(this.planTime[2]), 'seconds') < 0) {
                next = this.planTime[2]
            } else if (this.$moment().diff(this.$moment(this.planTime[3]), 'seconds') < 0) {
                next = this.planTime[3]
            }
            if (next) {
                this.nextTime = this.showTime(this.$moment(next).diff(this.$moment(), 'seconds'))
            } else {
                this.nextTime = '2022/06/29'
            }
        },
        showTime(val) {
            if (val < 60) {
                return val + "秒";
            } else {
                var min_total = Math.floor(val / 60); // 分钟
                var sec =
                    Math.floor(val % 60) > 9
                        ? Math.floor(val % 60)
                        : "0" + Math.floor(val % 60); // 余秒
                if (min_total < 60) {
                    return "00:" + min_total > 10 ? min_total : '0' + min_total + ":" + sec;
                } else {
                    var hour_total =
                        Math.floor(min_total / 60) > 9
                            ? Math.floor(min_total / 60)
                            : "0" + Math.floor(min_total / 60); // 小时数
                    var min =
                        Math.floor(min_total % 60) > 9
                            ? Math.floor(min_total % 60)
                            : "0" + Math.floor(min_total % 60); // 余分钟
                    return hour_total + ":" + min + ":" + sec;
                }
            }
        },
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.$bus.$emit('resize')
        })
        window.addEventListener(
            'scroll',
            () => {
                this.$bus.$emit('scrolling')
            },
            true
        )
        if (localStorage.getItem('Token') && isNotEmptyStr(localStorage.getItem('Token'))) {
            this.showLoadingToast()
            GetUserType(localStorage.getItem('Token'))
                .then(async (res) => {
                    if (res.response) {
                        this.$store.commit('setUserType', !res.response)

                    } else {
                        this.$store.commit('setUserType', !res.response)
                    }
                    this.$store.commit('saveToken', localStorage.getItem('Token'))
                    this.$store.commit('saveTokenExpire', localStorage.getItem('refreshtime'))
                    let re = await GetAllBaseData({ timeIndex: 1, diaryIndex: 1 })
                    this.baseData = re.data.response
                })
                .catch(() => {
                    this.$notify.error({
                        title: '错误',
                        message: '请检查网络',
                        duration: 3000
                    })

                })
                .finally(() => {
                    this.hideLoadingToast()
                })
        }
    }
}
</script>

<style lang="scss" scoped>
* {
    padding: 0;
    margin: 0 auto;
    box-sizing: border-box;
}

.cc-enter-active {
    /* 进入 / 离开 过度生效状态 */
    transition: all 1s;
}

.cc-enter {
    /* 进入状态 & 结束状态 opacity为0 */
    opacity: 0;
}

.pwd-btn {
    z-index: 0;
    background: #f78989;
    border-color: #f78989;
    color: #FFF;
    width: 150px;
    height: 40px;
    border-radius: 20px;
    position: relative;
}

.pwd-span {
    font-family: Arial, Helvetica, sans-serif;
    width: 100%;
    height: 100%;
    position: absolute;
    line-height: 40px;
    text-align: center;
    font-size: 1rem;
}

/deep/.el-loading-mask {
    border-radius: 20px;
    background-color: #F56C6C;
    display: flex;
    justify-content: center;
    align-items: center;
}

/deep/.el-loading-spinner {
    margin-top: 0;
    position: static;
    display: flex;
    justify-content: center;
    align-items: center;
}

/deep/.el-loading-spinner .circular {
    height: 25px;
    width: 25px;
}

/deep/.el-loading-spinner .path {
    stroke: #ffffff
}

/deep/.el-button--medium.is-round {
    width: 150px;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 2s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
    {
    opacity: 0;
}

.audio {
    .audio_wrap {
        width: 80vw;
    }
}

.birthday {
    position: relative;
    font-family: 'Indie Flower', cursive !important;
    background-color: #eee !important;
    color: #555 !important;
    text-align: center !important;
    padding: 4em 0 0 0 !important;
}

.music {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    animation: rotate 10s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -o-transform: rotate(360deg);
    }
}

$bookAngle: 60deg;
$speed: 5s;
$borderColor: #555;

$images: url('../../assets/imgs/birthday/1.jpg'),
    url('../../assets/imgs/birthday/1.jpg'),
    url('../../assets/imgs/birthday/1.jpg'),
    url('../../assets/imgs/birthday/1.jpg'),
    url('../../assets/imgs/birthday/1.jpg'),
    url('../../assets/imgs/birthday/1.jpg');

// Preload to images
.imgLoader {
    position: fixed;
    animation: preLoad 1s steps(1);
    width: 1px;
    height: 1px;

    @keyframes preLoad {
        @for $i from 0 through 4 {
            #{$i * 10}% {
                background-image: url('../../assets/imgs/birthday/1.jpg');
            }
        }

        100% {
            display: none;
        }
    }
}

.not_current_day_text {
    padding: 10vh 0 10vh 0;
}

.container {
    position: relative;
    width: 80vw;
    border: #fff solid 2px;
    border-radius: 4px;
    height: 420px;
}

.time {
    font-family: 'Indie Flower', Helvetica, cursive !important;
    position: relative;
    background-color: #eee !important;
    border-radius: 4px;
    height: auto;
}

.action_btn {
    font-family: 'Indie Flower', cursive !important;
    position: relative;
    background-color: #eee !important;
    border-radius: 4px;
    height: auto;
}

.audio {
    font-family: 'Indie Flower', cursive !important;
    position: relative;
    background-color: #eee !important;
    border-radius: 4px;
    height: auto;
    padding: 0 0 50px 0;
}

.power_info {
    font-family: 'Indie Flower', cursive !important;
    position: fixed;
    width: 100vw;
    height: 40px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eeeeeee6 !important;

    .time_out {
        font-family: Arial, Helvetica, sans-serif
    }
}

.genPwd {
    font-family: 'Indie Flower', cursive !important;
    position: relative;
    background-color: #eee !important;
    border-radius: 4px;
    height: auto;
    padding: 5vh 0 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.countUp {
    position: relative;
}

.title {
    position: absolute;
    top: 45px;
    left: 0;
    width: 100%;
    font-size: 2em;
    font-weight: normal;
    line-height: 1;
}

.credit {
    position: absolute;
    font-weight: normal;
    top: 103%;
    left: 0;
    font-size: 0.9em;
    text-align: left;
    line-height: 1;
}

.book {
    position: relative;
    perspective: 630px;
    perspective-origin: center 50px;
    transform: scale(1);
    filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.25));
}

.page {
    width: 210px;
    height: 300px;
    background-color: #bbb;
    position: absolute;
    top: 0px;
    right: 50%;
    transform-origin: 100% 100%;
    border: solid $borderColor 2px;
    background-size: 420px 300px;
    background-position: center;
    transform-style: preserve-3d;

    &:nth-child(1) {
        transform: rotateX($bookAngle) rotateY(3deg);
    }

    &:nth-child(2) {
        transform: rotateX($bookAngle) rotateY(4.5deg);
    }

    &:nth-child(3) {
        transform: rotateX($bookAngle) rotateY(6deg);
        animation: nextPage $speed * 5 infinite $speed * -4.8 steps(1);
        background-size: 420px 300px;
        background-position: -2px -2px;
    }

    &:nth-child(4) {
        transform: rotateX($bookAngle) rotateY(177deg);
    }

    &:nth-child(5) {
        transform: rotateX($bookAngle) rotateY(175.5deg);
    }

    &:nth-child(6) {
        transform: rotateX($bookAngle) rotateY(174deg);
        overflow: hidden;

        &::after {
            content: '';
            width: 210px;
            height: 300px;
            position: absolute;
            top: 0px;
            right: 0%;
            transform-origin: center;
            transform: rotateY(180deg);
            animation: nextPage $speed * 5 $speed * -4 infinite steps(1);
            background-size: 420px 300px;
            background-position: 100% -2px;
        }
    }

    @keyframes nextPage {
        @for $i from 0 through 4 {
            #{$i * 20}% {
                background-image: url('../../assets/imgs/birthday/1.jpg');
            }
        }
    }
}

.gap {
    width: 10px;
    height: 300px;
    background: none;
    transform: rotateX($bookAngle);
    transform-origin: bottom;
    position: absolute;
    top: 0px;
    left: calc(50% - 5px);

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
        background-color: $borderColor;
        width: 10px;
        height: 5px;
        border-radius: 50%;
    }
}

.pages,
.flips {
    transform-style: preserve-3d;
}

.flip {
    width: 32px;
    height: 300px;
    position: absolute;
    top: 0px;
    transform-origin: 100% 100%;
    right: 100%;
    border: solid $borderColor;
    border-width: 2px 0px;
    perspective: 4200px;
    perspective-origin: center;
    transform-style: preserve-3d;
    background-size: 420px 300px;

    &::after {
        content: '';
        position: absolute;
        top: 0px;
        right: 0%;
        width: 100%;
        height: 100%;
        transform-origin: center;
        background-size: 420px 300px;
    }

    &.flip1 {
        right: 50%;
        animation: flip1 $speed infinite ease-in-out;
        border-width: 2px 2px 2px 0;

        &::after {
            animation: nextFlip1 $speed * 5 $speed * -4 infinite steps(1);
        }
    }

    &:not(.flip1) {
        right: calc(100% - 2px);
        top: -2px;
        transform-origin: right;
        animation: flip2 $speed ease-in-out infinite;
    }

    @for $i from 2 through 7 {
        &.flip#{$i}::after {
            animation: nextFlip#{$i} $speed * 5 $speed * -4 infinite steps(1);
        }
    }

    &.flip7 {
        width: 30px;
        border-width: 2px 0px 2px 2px;

        &::after {
            animation: nextFlip7 $speed * 5 $speed * -4 infinite steps(1);
        }
    }

    @keyframes flip1 {

        0%,
        20% {
            transform: rotateX($bookAngle) rotateY(6deg);
        }

        80%,
        100% {
            transform: rotateX($bookAngle) rotateY(174deg);
        }
    }

    @keyframes flip2 {

        0%,
        20% {
            transform: rotateY(0deg) translateY(0px);
        }

        50% {
            transform: rotateY(-15deg) translateY(0px);
        }
    }
}

@keyframes nextFlip1 {
    @for $i from 0 through 4 {
        #{$i * 20}% {
            background-image: url('../../assets/imgs/birthday/1.jpg');
            background-position: -178px -2px;
            transform: rotateY(0deg);
        }

        #{10+($i * 20)}% {
            background-image: url('../../assets/imgs/birthday/1.jpg');
            background-position: -210px -2px;
            transform: rotateY(180deg);
        }
    }
}

@for $i from 2 through 6 {
    @keyframes nextFlip#{$i} {
        @for $j from 0 through 4 {
            #{$j * 20}% {
                background-image: url('../../assets/imgs/birthday/1.jpg');
                background-position: #{-148 + (($i - 2) * 30)}px -2px;
                transform: rotateY(0deg);
            }

            #{((10 + ($j * 20)) + (($i - 1) * 0.5))}% {
                background-image: url('../../assets/imgs/birthday/1.jpg');
                background-position: #{-238 - (($i - 2) * 30)}px -2px;
                transform: rotateY(180deg);
            }
        }
    }
}

@keyframes nextFlip7 {
    @for $i from 0 through 4 {
        #{$i * 20}% {
            background-image: url('../../assets/imgs/birthday/1.jpg');
            background-position: -2px -2px;
            transform: rotateY(0deg);
        }

        #{13+($i * 20)}% {
            background-image: url('../../assets/imgs/birthday/1.jpg');
            background-position: -388px -2px;
            transform: rotateY(180deg);
        }
    }
}

.loginout {
    position: absolute;
    bottom: 8px;
    left: 8px;
    color: #555;
    cursor: pointer;
}

.twitterLink {
    position: absolute;
    bottom: 8px;
    right: 8px;
    color: #555;
    cursor: pointer;

    & img {
        width: 2em;
        filter: grayscale(100%);
        transition: filter 0.25s;

        &:hover {
            filter: grayscale(0%);
        }
    }
}

.main-diary-btn {
    padding-top: 0 !important;
}

.main-wish-btn {
    padding-top: 10vh !important;
}
</style>
